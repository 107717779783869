body {
  max-width: 1200px;
  padding: 0 50px;
}

header {
  background: url('../images/bee-head.png') no-repeat 480px 50%;
}

header img {
  position: relative;
  top: 30px;
}

.section-1-about {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-top: 30px;
}

/* Section 1 */

.section-1-about > div:first-child {
  width: 365px;
}

.section-1-about > div:last-child {
  flex: 1;
  padding-left: 30px;
}

.section-1-about > div:last-child video {
  height: 280px;
  object-fit: contain;
  object-position: left top;
  margin-top: 0px;
}

.section-1-about .btn {
  width: 250px;
}

/* Section 2 */

.section-2-analitika {
  padding-top: 20px;
  margin-bottom: 50px;
}

.section-2-analitika > div:first-child {
  max-width: 600px;
}

.section-2-analitika div.stats {
  display: flex;
  flex-direction: row;
  max-width: 1000px;
}

.section-2-analitika h2 {
  padding-left: 0px;
}

.stats .dataGroup {
  flex: 1;
  margin: 0;
}

.stats {
  padding-top: 20px;
}

.stats .caqi {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  line-height: 1;
  margin: 20px 0 20px 0;
  padding: 0;
  margin: 0 30px 0 0;
}

.stats .caqi > div:first-child {
  display: block;
  font-size: 80px;
}

.stats .caqi > div:last-child {
  font-size: 24px;
}

.stats .data {
  width: 25%;
  aspect-ratio: none;
  height: 100px;
  box-sizing: border-box;
  max-width: 150px;
  border-right: 1px dashed black;
  border-bottom: 1px dashed black;
}

.stats .dataGroup {
  max-width: 560px;
  height: 200px;
}

@media (max-width: 1400px) {
  .stats .dataGroup {
    margin-right: -80px;
  }
}

.stats .data:nth-child(4n + 4) {
  border-right: none;
}

.stats .data:nth-child(5),
.stats .data:nth-child(6),
.stats .data:nth-child(7),
.stats .data:nth-child(8) {
  border-bottom: none;
}

.stats .data:nth-child(9) {
  display: none;
}

/* Section 3 */

.section-3-smart {
  display: flex;
  flex-direction: row;
  margin-left: -50px;
  margin-right: -50px;
  padding-left: 80px;
}

.section-3-smart > div:first-child {
  width: 355px;
  margin-right: 40px;
}

.section-3-smart > div:last-child {
  flex: 1;
}

.section-3-smart img {
  height: 100%;
  object-fit: contain;
}

/* Section 3 quote */

.section-3-quote {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 30px 30px 10px;
}

.section-3-quote .img {
  margin: 0;
  margin-right: -200px;
  margin-right: max(-20vw, -200px);
}

.section-3-quote img {
  width: 400px;
  width: min(40vw, 400px);
}

.section-3-quote .quote {
  flex: 1;
  padding: 0;
  padding-left: 200px;
  padding-left: min(20vw, 200px);
}

.section-3-quote .quote p {
  max-width: 365px;
  padding: 0 20px 0 20px;
}

.section-3-quote .bees {
  display: none;
}

/* Section 4 */

.section-4-edu {
  display: flex;
  flex-direction: row;
}

.section-4-edu .text2 {
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 0 40px 0;
}

.section-4-edu .text2 .btn {
  min-width: auto;
  padding-right: 80px;
}

.section-4-edu .text2 .btn:last-child {
  margin-left: 20px;
}

.section-4-edu > div {
  width: 33%;
  min-width: 300px;
  padding-right: 15px;
}

.section-4-edu > p {
  flex: 1;
  padding-left: 15px;
}

/* Section 5 */

.section-5-videos {
  padding: 20px 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.section-5-videos a {
  width: calc(33% - 30px);
}

.section-5-videos span {
  padding: 0;
}

/* Section 6 */

.section-6-gallery.winner {
  background: #f2f2f2;
  padding: 10px 50px 0 50px;
  margin: -10px -50px 0 -50px;
}

.section-6-title .title3 {
  max-width: 410px;
}

.section-6-creative {
  padding: 20px 30px;
}

.section-6-vote {
  padding: 10px 30px;
}

.section-6-vote > * {
  width: 190px;
  height: 50px;
}

.section-6-vote-winner img {
  top: -25px;
  left: -5px;
}

.section-6-winners {
  padding: 0;
}


.section-7 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 30px 50px;
  background: #f2f2f2;
}

.section-7-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section-7 img {
  height: 100%;
  object-fit: contain;
}

.simple-link {
  margin-bottom: 0;
}

/* Section 1 */

.section-7 > div:first-child {
  width: 365px;
}

.section-7 > div:last-child {
  flex: 1;
  padding-left: 30px;
}

.section-7 .btn {
  width: 250px;
}

.section-8 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 30px 50px;
  background: #f8f8f8;
}

.section-8-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 365px;
}

.section-8 img.map {
  object-fit: contain;
  margin-top: 0;
}

.section-8 > div:last-child {
  flex: 1;
  padding-left: 50px;
  height: 450px;
  position: relative;
}

.section-8-thank-you-note-container {
  position: relative;
}

.section-8-certificate {
  display: block;
  position: absolute;
  z-index: 1;
  top: -125px;
  right: 50px;
  width: 100%;
  max-width: 500px;
  height: 650px;
  border: 1px solid #f2f2f2;
}

.section-9-competiton {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 30px;
}

footer {
  font-weight: 300;
  font-size: 18px;
  line-height: 29px;
  color: #666666;
}

footer > div {
  display: flex;
  flex-direction: column;
}

footer .logos {
  display: flex;
  align-items: center;
}

footer .logos a:first-child img {
  width: 150px;
}

footer .logos a:last-child img {
  width: 180px;
  margin-left: 20px;
}

footer .logos img {
  margin: 0 0 10px 0;
}

/* Page */
.page .back {
  margin: 0;
}

.page .content {
  flex-direction: row;
  padding: 0;
}

.page .content > div:first-child {
  flex: 1;
}

.page .menu {
  width: 200px;
  margin-left: 60px;
  padding-top: 20px;
}

ul.withimgs li {
  width: 40%;
}

ul.withimgs strong {
  align-self: flex-start;
  margin-top: 10px;
  margin-bottom: 0px;
}
