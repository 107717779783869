body {
    padding: 0 100px;
    background: white;
}

html {
    background: url('../images/bg.png');
}

header {
    background: none;
    position: relative;
}

header video {
    display: block;
    position: absolute;
    width: 50%;
    top: 20px;
    right: 80px;
}

footer {
    background: none;
    position: relative;
    padding-bottom: 80px;
}

footer video {
    display: block;
    max-width: calc(100% - 550px);
    position: absolute;
    top: 0px;
    right: 60px;
    width: 600px;
}

/* Section 1 */

.section-1-about > div:last-child video {
    height: 400px;
}

/* Section 2 */

.section-2-analitika {
    display: flex;
    flex-direction: row;
}

.section-2-analitika > div:first-child {
    max-width: 360px;
}

.section-2-analitika > div:last-child {
    flex: 1;
    margin-left: 30px;
}

/* Section 3 */

.section-3-smart {
    margin-left: -100px;
    margin-right: -100px;
    padding-left: 120px;
}

.section-3-smart > div:first-child {
}

.section-3-smart img {
    object-fit: cover;
}

/* Section 3 quote */

.section-3-quote {
    display: flex;
    flex-direction: row;
}

.section-3-quote .img {
}

.section-3-quote img {
}

.section-3-quote .quote {
}

.section-3-quote .quote p:first-child {
}

@media (min-width: 1440px) {
    .section-3-quote .bees {
        display: block;
        background: url('../images/quote-bees.png') no-repeat 0% 0%;
        width: 484px;
        height: 383px;
        right: 60px;
        bottom: auto;
        top: 60px;
        aspect-ratio: auto;
    }
}

/* Section 4 Edu */

.section-4-edu {
    padding-bottom: 0;
    margin-bottom: -10px;
}

.text2 {
    display: block;
}

.text2 video {
    width: 90%;
}

/* Section 6 gallery */

.section-6-gallery.winner {
    background: #f2f2f2;
    padding: 10px 100px 0 100px;
    margin: -10px -100px 0 -100px;
}

.section-6-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.section-6-container {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.section-finished-competition {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 30px;
    align-items: center;
}

.section-finished-competition img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.section-7 {
    margin: 0 -100px;
    padding-left: 130px;
}

.section-7 img {
    object-fit: cover;
    width: 715px;
}

.section-7-page-description {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    margin-bottom: 50px;
}

.section-7-page-paragraph {
    margin-bottom: 0;
}

.zelim-beeti-page-paragraph-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.section-8 {
    margin: 50px -100px;
    padding-left: 130px;
}

.section-8 img.map {
    object-fit: cover;
    width: 650px;
    position: absolute;
    top: -75px;
}

.section-8-page-description {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
}

.section-8-form-container {
    margin: 75px -300px;
    height: 600px;
    position: relative;
    background-color: #f2f2f2;
}

.section-8-form-bees {
    display: block;
    position: absolute;
    top: 20%;
    left: 5%;
}

.section-8-form-bees-right {
    display: block;
    position: absolute;
    top: 20%;
    right: 3%;
}

.section-8-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.section-9-competiton {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-top: 30px;
}

a.pobjednici,
a.svi-radovi {
    margin-right: 20px;
    margin-bottom: 0;
    width: 190px;
}

/* Pages */

ul.withimgs li {
    width: calc(33% - 25px);
    margin: 10px;
}

ul.withimgs.half li {
    width: 40%;
    margin-right: 40px;
    text-align: left;
}
