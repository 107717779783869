@font-face {
    font-family: 'A1Serif';
    src: url('../fonts/A1Serif-Regular.woff') format('woff');
    font-weight: normal;
}

@font-face {
    font-family: 'A1Serif';
    src: url('../fonts/A1Serif-Bold.woff') format('woff');
    font-weight: bold;
}

@font-face {
    font-family: 'A1Sans';
    src: url('../fonts/A1Sans-Regular.woff') format('woff');
    font-weight: normal;
}

@font-face {
    font-family: 'A1Sans';
    src: url('../fonts/A1Sans-Bold.woff') format('woff');
    font-weight: bold;
}

body {
    padding: 0;
    font-family: A1Sans, sans-serif;
    min-width: 320px;
    max-width: 600px;
    margin: 0 auto;
}

h1,
h2,
h3 {
    font-family: A1Serif, serif;
    line-height: 1.3em;
}

header {
    padding: 0 30px 50px;
}

header img {
    max-width: 100%;
}

header video {
    display: none;
}

.section-1-about {
    padding: 0 30px 50px;
}

.section-1-about video {
    width: 100%;
    margin-top: 60px;
}

.subtitle {
    font-size: 14px;
    color: #000000;
    text-transform: uppercase;
}

.title {
    font-weight: bold;
    font-size: 30px;
    color: #000000;
    padding-right: 50px;
}

.text {
    font-size: 18px;
    line-height: 1.5em;
    color: #000000;
}

a.btn {
    height: 74px;
    background: #da291c url('../images/arrow-right.svg') no-repeat calc(100% - 20px) 50%;
    font-family: A1Serif, serif;
    font-weight: bold;
    font-size: 21px;
    line-height: 1.5em;
    display: flex;
    align-items: center;
    color: #ffffff;
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    margin-top: 30px;
    text-decoration: none;
    transition: background 400ms ease-in-out;
}

a.btn:hover {
    opacity: 0.9;
    background-position-x: calc(100% - 15px);
}

a.btn.download {
    background: #da291c url('../images/dl-icon.png') no-repeat calc(100% - 20px) 50%;
}

.section-2-analitika {
    padding: 50px 35px 0px;
}

.section-2-analitika h2 {
    font-family: A1Sans, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: normal;
}

.title2 {
    font-weight: bold;
    font-size: 36px;
    color: #000000;
}

.text2 {
    padding: 20px 0 30px 0;
}

.title3 {
    font-weight: bold;
    font-size: 30px;
    max-width: 320px;
    color: #000000;
}

hr {
    border: none;
    border-top: 1px dotted black;
    background-color: #fff;
    height: 1px;
    width: 100%;
}

.stats .caqi {
    height: 100px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    color: white;
    background: #d1cf1e;
    margin: 20px -35px 20px -35px;
    padding: 10px 0 0 0;
    box-sizing: border-box;
    line-height: 100px;
    font-weight: bold;
}

.stats .caqi > div:first-child {
    font-size: 80px;
}

.stats .caqi > div:last-child {
    font-size: 24px;
}

.stats .dataGroup {
    margin: 0 -35px 0 -35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.stats .data {
    aspect-ratio: 1 / 1;
    width: 33%;
    max-width: 150px;
    text-align: center;
    /*background: lightgreen;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.stats .data > * {
    margin: 0;
}

.tooltip {
    max-width: 200px;
}

@media (max-width: 799px) {
    .stats .data {
        border-right: 1px dashed black;
        border-bottom: 1px dashed black;
    }

    .stats .data:nth-child(3n + 3) {
        border-right: none;
    }

    .stats .data:nth-child(7),
    .stats .data:nth-child(8),
    .stats .data:nth-child(9) {
        border-bottom: none;
    }
}

.stats .type {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.stats .type sup {
    font-size: 0.7em;
}

.stats .value b {
    font-size: 24px;
}

.stats .value span {
    font-size: 18px;
}

/* Section 3 */

.section-3-smart {
    background: #f8f8f8;
    padding: 0 0 0 30px;
    margin-top: 50px;
    margin-bottom: 30px;
}

.section-3-smart p {
    line-height: 1.5em;
    margin-top: 30px;
}

.section-3-smart > div:first-child {
    margin-bottom: 40px;
    padding-top: 20px;
    padding-right: 40px;
}

.section-3-smart > div:last-child {
    margin-left: -30px;
}

.section-3-smart img {
    max-width: 100%;
}

/* Section 4 - education */

.section-4-edu {
    padding: 30px 30px 20px 30px;
}

.section-4-edu .text2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 10px 0;
}

.section-4-edu .text2 .btn {
    font-size: 1em;
    height: 60px;
    min-width: 260px;
    max-width: 320px;
}

.bees-svg {
    padding: 0px 35px;
}

/* Section 5 */

.section-5-videos {
    margin-bottom: 10px;
}

.section-5-videos a {
    text-decoration: none;
    margin-bottom: 25px;
}

.section-5-videos a:hover img {
    opacity: 0.8;
}

.section-5-videos img {
    width: 100%;
    margin-bottom: 15px;
    transition: opacity 0.2s ease-in-out;
}

.section-5-videos span {
    display: block;
    font-weight: bold;
    padding: 0 35px;
    font-size: 18px;
    line-height: 0px;
    color: #000000;
    margin: 15px 0 40px 0;
}

/* Section 6 */

/* .section-6-title {
  padding: 30px 30px 20px 30px;
} */

.section-6-names {
    padding: 0px 30px 0px 30px;
    font-size: 21px;
}

.section-6-wrap {
    margin-bottom: 40px;
}

.section-6-creative .gallery-item {
    text-decoration: none;
    margin-right: 20px;
    cursor: pointer;
}

.section-6-creative .gallery-item:hover img {
    opacity: 0.8;
}

.section-6-creative img {
    width: 100%;
    transition: opacity 0.2s ease-in-out;
    aspect-ratio: 390 / 225;
    object-fit: cover;
}

.section-6-creative p {
    display: block;
    padding: 0 35px;
    font-size: 18px;
    line-height: 1.5em;
    color: #000000;
    margin: 10px 0 20px 0;
}

.section-6-vote {
    padding: 0px 30px 20px 30px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.section-6-vote > * {
    width: 140px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
}

.section-6-vote-btn {
    background: #da291c;
    color: white;
    cursor: pointer;
}

.section-6-vote-btn:hover {
    opacity: 0.8;
    transition: opacity ease-in-out 0.2s;
}

.section-6-vote-count {
    border: 1px solid #666;
    box-sizing: border-box;
    color: #666;
}

.section-6-vote-winner {
    position: relative;
}

.section-6-vote-winner img {
    position: absolute;
    top: -35px;
    left: -5px;
}

.section-6-gallery.winner {
    background: #f2f2f2;
    padding: 10px 0 0 0;
    margin: -10px 0 0 0;
}

.section-6-buttons {
    flex-direction: column;
}

.section-6-winners {
    margin: 50px 0;
    padding: 20px;
}

.section-6-winners img {
    width: 100%;
    margin-bottom: 30px;
}

.section-7 {
    background: #f8f8f8;
    padding: 20px;
}

.section-7 img {
    max-width: 100%;
}

.section-7-links {
    display: flex;
    flex-direction: column;
}

.simple-link {
    margin: 20px 0;
    color: #8a8a8a;
}

.section-7-page {
    margin-top: 20px;
    width: 100%;
}

.section-7-radovi {
    background: #f8f8f8;
    margin: 50px 0;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-7-radovi-title {
    color: #a8a8a8;
}

.section-8 {
    background: #f8f8f8;
    padding: 20px;
}

.section-8 img.map {
    max-width: 650px;
    width: 100%;
    margin-top: 30px;
}

.section-8-image-container {
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    padding: 20px;
}

.section-8-img {
    width: 80%;
}

.section-8 .btn {
    width: 250px;
}

.section-8-form-container {
    display: flex;
    justify-content: center;
}

.section-8-form-bees {
    display: none;
}

.section-8-form-bees-right {
    display: none;
}

.section-8-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 25px;
    padding: 50px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    max-width: 500px;
    width: 100%;
}

.section-8-row {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.section-8-label {
    font-weight: 700;
    font-size: 20px;
}

.section-8-input {
    margin: 10px 0 30px;
    border: none;
    background-color: #f8f8f8;
    height: 25px;
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 16px;
}

.section-8-input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.section-8-input::placeholder {
    text-align: center;
    color: #aaa4a4;
    font-weight: bold;
}

.section-8-input:focus {
    outline: none;
}

.section-8-suggested-amount {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.section-8-amount {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
    padding: 15px 5px;
    border-radius: 25px;
    font-weight: bold;
    border: 1px solid transparent;
    box-sizing: border-box;
}

.section-8-amount.selected {
    border-color: #da291c;
}

.section-8-agreement {
    display: flex;
    align-items: baseline;
    gap: 5px;
    margin-bottom: 20px;
}

.section-8-submit-btn {
    background-color: #da291c;
    width: 200px;
    font-family: A1Serif, serif;
    font-weight: bold;
    font-size: 21px;
    color: white;
    border: none;
    height: 50px;
    cursor: pointer;
}

.section-8-submit-btn[disabled] {
    opacity: 0.7;
}

button.section-8-amount {
    cursor: pointer;
}

.section-8-certificate-container {
    margin: 75px -300px;
    height: 400px;
    position: relative;
    background-color: #f2f2f2;
}

.section-8-thank-you-note-container {
    margin: 0 300px;
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    position: relative;
}

.section-8-thank-you-note-container a {
    color: white !important;
}

.section-8-thank-you-note-title {
    font-size: 40px;
}

.section-8-certificate {
    display: none;
}

.section-9-competiton {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
}

.section-9-competiton img, .section-finished-competition img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.section-finished-competition {
    margin-bottom: 30px;
}

.section-9-competiton .btn {
    color: white !important;
    margin-top: 0;
}

.subtitle-bold {
    font-weight: bold;
}

.left-btn,
.right-btn {
    width: 52px;
    height: 52px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateY(-20px);
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
}

.left-btn {
    background: url('../images/cleft.png') no-repeat;
    left: -4px;
}

.right-btn {
    background: url('../images/cright.png') no-repeat;
    right: 20px;
}

.left-btn:hover,
.right-btn:hover {
    opacity: 0.8;
}

a.pravila {
    color: #da291c;
    font-size: 18px;
    font-weight: bold;
}

a.pravila:hover {
    opacity: 0.8;
}

a.pobjednici,
a.svi-radovi {
    display: inline-block;
    width: 100%;
    height: 50px;
    background: #da291c;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    margin-bottom: 20px;
}

/* Footer */

footer {
    font-weight: normal;
    font-size: 18px;
    line-height: 1.5em;
    color: #666666;
    padding: 0px 30px 20px 30px;
}

footer .logos {
    margin-bottom: 40px;
}

footer .logos img {
    margin: 0 auto;
    display: block;
}

footer .logos img:first-child {
    margin-bottom: 20px;
}

footer video {
    display: none;
}

@media (max-width: 450px) {
    footer .logos img {
        max-width: 300px;
    }
}

/* Section 3 quote */

.section-3-quote {
    padding: 20px 0 60px 0;
    position: relative;
}

.section-3-quote .bees {
    background: url('../images/quote-2-bees.png') no-repeat 50% 100%;
    width: 100%;
    aspect-ratio: 271 / 153;
    position: absolute;
    bottom: 0px;
}

.section-3-quote img {
    clip-path: polygon(10% 25%, 10% 75%, 50% 95%, 90% 75%, 90% 25%, 50% 5%);
    width: 100%;
    height: auto;
}

.section-3-quote .img {
    filter: drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.2));
    margin-bottom: calc(-100vw / 2);
}

.section-3-quote .quote {
    padding: calc(100vw / 2) 20px 60px 20px;
    line-height: 1.5em;
    font-size: 18px;
    background: #f2f2f2;
}

.section-3-quote .quote p:first-child {
    padding-top: 20px;
}

.section-3-quote .quote p:last-child {
    font-weight: bold;
    padding-top: 10px;
}

/* Page */

.page .back {
    display: flex;
    background: url('../images/left.png') no-repeat 0 50%;
    padding: 3px 0 0 30px;
    text-transform: uppercase;
    font-size: 12px;
    text-decoration: none;
    color: #666;
    font-weight: bold;
    align-items: center;
    line-height: 30px;
    height: 30px;
}

.page {
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.5em;
    padding-bottom: 80px;
}

.page > img,
.page > video,
.page > iframe {
    max-width: 100%;
    margin-bottom: 20px;
}

.page h1 {
    margin-bottom: 30px;
    max-width: 500px;
}

.page .back {
    margin-left: 20px;
}

.page .content {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
}

.page .menu h2 {
    font-family: A1Sans;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.page .menu ul {
    padding: 0;
}

.page .menu li {
    font-size: 13px;
    list-style: none;
    padding: 0;
    border-bottom: 1px dotted #666;
}

.page .menu li:last-child {
    border-bottom: none;
}

.page .menu a {
    text-decoration: none;
    color: #666;
    padding: 10px 0;
    display: block;
    transition: color 0.2s ease-in-out;
}

.page .menu a:hover {
    color: black;
}

.content > *:first-child a {
    color: #da291c;
}

.content > *:first-child a:hover {
    text-decoration: none;
    opacity: 0.8;
}

ul.withimgs {
    margin-top: 40px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}

ul.withimgs > li {
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

ul.withimgs img {
    width: 120px;
    height: 120px;
    object-fit: contain;
    margin-bottom: 10px;
}

ul.withimgs.half img {
    width: 90%;
    max-width: 180px;
}

ul.withimgs strong {
    margin-bottom: 10px;
}

ul.biljke strong {
    text-align: center;
    width: 100%;
}

.five-rec li {
    margin-top: 20px;
}

/* Modal */

.modal {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    pointer-events: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal img {
    max-width: 90%;
    max-height: 90%;
}

/* React-Modal */
.Modal {
    width: min(90vw, 500px);
    padding: 20px 20px 40px 20px;
    background-color: white;
    box-sizing: border-box;
}

.winners-modal {
    max-width: 800px;
    width: 100%;
    max-height: 450px;
    height: 100%;
    outline: none;
}

.winners-image {
    max-width: 800px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.Modal p {
    font-size: 12px;
    line-height: 1.4;
}

.Modal .form {
    display: flex;
    margin-top: 30px;
    flex-direction: row;
    gap: 10px;
}

.Modal .form input[type='email'] {
    height: 30px;
    padding: 4px;
    flex: 1;
}

.Modal .form input[type='submit'] {
    border: none;
    background: #da291c;
    color: white;
    font-weight: 700;
    padding: 0 30px;
    cursor: pointer;
}

.Modal .form input[type='submit']:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}

.Modal .form input[type='submit']:hover {
    opacity: 0.7;
}
